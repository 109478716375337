import React, { useReducer, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../assets/styles/contact.scss';

const Contact = ({ data }) => {
  const page = data.datoCmsContact;
  const [formSubmission, setFormSubmission] = useState(null);
  const [contactDetails, setcontactDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      email: '',
      subject: '',
      body: '',
    },
  );
  const [contactDetailsErrors, setcontactDetailsErrors] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      email: '',
      subject: '',
      body: '',
    },
  );
  const contactFormState = useRef(contactDetails);

  const isValid = () => {
    let valid = true;
    const { current } = contactFormState;
    setcontactDetailsErrors({ name: current.name === '' });
    setcontactDetailsErrors({ body: current.body === '' });
    setcontactDetailsErrors({ subject: current.subject === '' });
    setcontactDetailsErrors({ email: current.email === '' || !/^.+@.+\..+$/.test(current.email) });

    if (current.name === '') {
      valid = false;
    }
    if (current.body === '') {
      valid = false;
    }
    if (current.subject === '') {
      valid = false;
    }
    if (current.email === '' || !/^.+@.+\..+$/.test(current.email)) {
      valid = false;
    }

    return valid;
  };

  const handleInputChange = (e) => {
    setcontactDetails({ [e.target.name]: e.target.value });
    contactFormState.current[e.target.name] = e.target.value;
    isValid();
  };

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': e.target.getAttribute('name'),
          ...contactDetails,
        }),
      })
        .then(() => setFormSubmission('success'))
        .catch(() => setFormSubmission('error'));
    }
  };

  return (
    <Layout>
      {page.seo && <SEO title={page.seo.title} description={page.seo.description} />}
      <h1>{page.title}</h1>
      <form
        className="contact-form"
        name="contact"
        data-netlify="true"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="input-wrapper">
          <label>Your name</label>
          <input
            name="name"
            placeholder=" "
            type="text"
            onChange={(e) => {
              handleInputChange(e);
            }}
            className={`${contactDetailsErrors.name ? 'input-error' : ''}`}
          />
        </div>
        <div className="input-wrapper">
          <label>Your email</label>
          <input
            name="email"
            placeholder=" "
            type="email"
            autoCorrect="off"
            autoCapitalize="off"
            onChange={(e) => {
              handleInputChange(e);
            }}
            className={`${contactDetailsErrors.email ? 'input-error' : ''}`}
          />
        </div>
        <div className="input-wrapper">
          <label>Subject</label>
          <input
            name="subject"
            placeholder=" "
            type="text"
            onChange={(e) => {
              handleInputChange(e);
            }}
            className={`${contactDetailsErrors.subject ? 'input-error' : ''}`}
          />
        </div>
        <div className="input-wrapper">
          <label>Message</label>
          <textarea
            name="body"
            placeholder=" "
            onChange={(e) => {
              handleInputChange(e);
            }}
            className={`${contactDetailsErrors.body ? 'input-error' : ''}`}
          />
        </div>
        {formSubmission === 'success' && (
          <p>
            Thank you. We have received your message and will get back to you as soon as possible
          </p>
        )}
        {formSubmission === 'error' && (
          <p>
            oops, there&apos;s been a problem sending your message. Please try again later.
          </p>
        )}
        <button type="submit">Send</button>
      </form>
    </Layout>
  );
};

Contact.propTypes = {
  data: PropTypes.shape().isRequired,
};

export const query = graphql`
  query Contact {
    datoCmsContact {
      title
      seo {
        description
        title
      }
    }
  }
`;

export default Contact;
